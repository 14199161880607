import {
  Avatar,
  Box,
  BoxProps,
  Button,
  chakra,
  ComponentWithAs,
  Flex,
  Heading,
  Icon,
  IconProps,
  Img,
  Link as ChakraLink,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
} from "@chakra-ui/react"
import * as React from "react"
import { graphql, Link as GatsbyLink, PageProps } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { gsap } from "gsap"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import { EmailIcon } from "@chakra-ui/icons"
import {
  FaBinoculars,
  FaCheckCircle,
  FaHammer,
  FaHandshake,
  FaPencilRuler,
  FaPhone,
  FaTimesCircle,
  FaTree,
} from "react-icons/fa"
import { IconType } from "react-icons"

import Layout from "../components/layout"
import ContactForm from "../components/contactForm"
import { Link } from "../components/ui"
import BlockHeader from "../components/blockHeader"
import { IQueriedGatsbyImage, UniformBoxProps } from "../components/utils"
import FAQVideo from "../components/faqVideo"
import SEO from "../components/CoreUI/SEO"

type GetStartedProps = PageProps & {
  data: {
    img: IQueriedGatsbyImage
    testimonialImg: IQueriedGatsbyImage
  }
}

type FeatureProps = Pick<BoxProps, Extract<keyof BoxProps, `pt`>> & {
  body: string | React.ReactChild
  heading: string
  icon: ComponentWithAs<`svg`, IconProps> | IconType
}

type ValueToCompare =
  | `Cost`
  | `Cutomizable`
  | `Move-in Time`
  | `Reliability`
  | `Warranty`
  | `Wear & Tear`

type ComparedValue = {
  isSuperior: boolean
  value: number | string
}

type ComparedValues = { [key in ValueToCompare]: ComparedValue }

function UniformBox(props: UniformBoxProps): JSX.Element {
  return (
    <Box
      as="section"
      maxW={[`95vw`, `90vw`, `750px`, `1000px`]}
      mx="auto"
      overflowY="auto"
      w={{ sm: `650px`, md: `100%` }}
      {...props}
    />
  )
}

function Feature({ body, heading, icon, pt = 0 }: FeatureProps): JSX.Element {
  return (
    <Stack direction="row" pt={pt} spacing="24px" w="100%">
      <Flex
        align="center"
        bg="red.500"
        color="white"
        h={12}
        justify="center"
        rounded="md"
        shrink={0}
        w={12}
      >
        <Icon as={icon} h={6} w={6} />
      </Flex>
      <Stack>
        <Heading
          as="h3"
          color="gray.700"
          fontFamily="body"
          fontSize="xl"
          fontWeight="extrabold"
        >
          {heading}
        </Heading>
        <Text lineHeight="tall" mt={2} pr={6}>
          {body}
        </Text>
      </Stack>
    </Stack>
  )
}

const customBuildComparedValues: ComparedValues = {
  Cost: {
    isSuperior: false,
    value: `+5%`,
  },
  Cutomizable: {
    isSuperior: true,
    value: `Fully`,
  },
  "Move-in Time": {
    isSuperior: false,
    value: `8-12 months`,
  },
  Reliability: {
    isSuperior: true,
    value: `Excellent`,
  },
  Warranty: {
    isSuperior: true,
    value: `2-10`,
  },
  "Wear & Tear": {
    isSuperior: true,
    value: `None`,
  },
}

const purchasedHomeComparedValues: ComparedValues = {
  Cost: {
    isSuperior: true,
    value: `+0%`,
  },
  Cutomizable: {
    isSuperior: false,
    value: `No`,
  },
  "Move-in Time": {
    isSuperior: true,
    value: `1-2 months`,
  },
  Reliability: {
    isSuperior: false,
    value: `Unknown`,
  },
  Warranty: {
    isSuperior: false,
    value: `None`,
  },
  "Wear & Tear": {
    isSuperior: false,
    value: `Yes`,
  },
}

const features: FeatureProps[] = [
  {
    body: (
      <>
        Thanks to a close partnership with our friends at{" "}
        <Link href="https://www.sundogrealty.com/">Sundog Realty</Link>, we can
        work with our clients and their agents to find the best land for their
        plan.
      </>
    ),
    heading: `No land, no problem`,
    icon: FaTree,
  },
  {
    body: (
      <>
        Whether you customize one of our plans or decide to bring your own,
        you'll enjoy the same outstanding client experience and the protection
        of our industry-leading new home warranty.
      </>
    ),
    heading: `Bring your own plan — or not`,
    icon: FaPencilRuler,
  },
  {
    body: (
      <>
        Thanks to our online project management tool, our clients can easily
        track build progress, review the construction schedule, and see photos
        from the jobsite — all from the comfort of home (wherever that may be).
      </>
    ),
    heading: `Follow your build from anywhere`,
    icon: FaBinoculars,
  },
]

function GetStarted({
  data: { img, testimonialImg },
  location,
}: GetStartedProps): JSX.Element {
  gsap.registerPlugin(ScrollToPlugin)

  const section1Ref = React.useRef<HTMLDivElement>()
  const contactSectionRef = React.useRef<HTMLDivElement>()

  const scrollToSection = (
    section: React.MutableRefObject<HTMLDivElement>
  ): void => {
    if (typeof window === `undefined`) return
    const scrollTo = { offsetY: 80, y: section.current }
    gsap.to(window, { duration: 0.5, scrollTo })
  }

  const handleShowMeButton = (): void => {
    scrollToSection(section1Ref)
  }

  const handleGetInTouchButton = (): void => {
    scrollToSection(contactSectionRef)
  }

  return (
    <Layout>
      <SEO
        meta={{
          description:
            "Building a custom home with Sundog Homes is a simple and stress-free process. Get started with one of our plans, or bring us yours.",
          image: `${location.origin}/sundog-design-studio-team-1.jpg`,
          imageAlt: `Brannon, Phillip, and David of Sundog Homes`,
        }}
        title="Getting Started With Sundog | Sundog Homes"
        url={location.href}
      />

      <Box bg="gray.50" pb={24} pt={16}>
        <UniformBox>
          <Stack
            align="center"
            direction={{ base: `column`, lg: `row` }}
            justify="space-between"
            mx="auto"
            spacing="32px"
          >
            <Stack maxW={{ md: `750px`, lg: "520px" }}>
              <BlockHeader bg="gray.700" w="280px" textAlign="center">
                Get Started with Sundog
              </BlockHeader>
              <Text
                color="red.500"
                fontFamily="heading"
                fontSize={[`4xl`, `5xl`]}
                fontWeight="extrabold"
                lineHeight="1"
                pt={8}
              >
                Building a custom home may be more accessible than you think.
              </Text>
              <Text color="gray.600" fontSize="lg" fontWeight="medium" pt={4}>
                At Sundog Homes, the days of overwhelming, overextended, and
                over-budget home builds are over.
              </Text>
              <Stack
                direction={[`column`, , `row`]}
                pb={2}
                pt={8}
                px={1}
                spacing="12px"
              >
                <Button
                  colorScheme="red"
                  onClick={handleShowMeButton}
                  size="lg"
                  variant="solid"
                  w={{ base: `full`, md: `auto` }}
                >
                  Get Started
                </Button>
                <Button
                  bg="white"
                  leftIcon={<Icon as={EmailIcon} />}
                  onClick={handleGetInTouchButton}
                  shadow="base"
                  size="lg"
                  variant="solid"
                  w={{ base: `full`, md: `auto` }}
                >
                  Schedule a Consult
                </Button>
              </Stack>
            </Stack>

            <Flex h="full" justify="center" w="full">
              <StaticImage
                alt="The Sundog Homes team"
                backgroundColor="transparent"
                height={600}
                placeholder="blurred"
                src="../images/sundog-design-studio-team-1.jpg"
                style={{
                  borderRadius: `var(--chakra-radii-base)`,
                  boxShadow: `var(--chakra-shadows-md)`,
                  minHeight: 350,
                }}
                transformOptions={{ fit: `cover` }}
              />
            </Flex>
          </Stack>
        </UniformBox>
      </Box>

      <Box bg="white" py={24}>
        <UniformBox>
          <Stack px={1} py={4} ref={section1Ref}>
            <Heading
              as="h2"
              color="gray.700"
              fontWeight="bold"
              textAlign="center"
            >
              A flexible, secure, and informed homebuilding experience
            </Heading>
            <Flex
              align="center"
              flexDir={{ base: `column`, lg: `row` }}
              justify="space-between"
              pt={24}
            >
              <Stack maxW={{ base: `100%`, lg: `40%` }}>
                {features.map((feature: FeatureProps, index: number) => (
                  <Feature
                    {...feature}
                    key={index}
                    pt={index > 0 ? 12 : null}
                  />
                ))}
              </Stack>
              <FAQVideo
                oEmbedUrl="https://sundoghrv.wistia.com/medias/ens75cysxa?embedType=iframe&videoFoam=true&videoWidth=640"
                question="I found a great home plan online. Should I share it with a builder before I buy it?"
                videoDescription="Phillip and David discuss the benefits of sharing online plans with your builder."
                w={{ base: `100%`, lg: `55%` }}
              />
            </Flex>
          </Stack>
        </UniformBox>
      </Box>

      <Box bgGradient="linear(177deg, white 50%, gray.100 50%)">
        <UniformBox>
          <Stack direction={[`column`, , , `row`]} py={6} spacing="16px">
            <StaticImage
              alt="This kitchen expertly blends rustic and contemporary design."
              height={500}
              src="../images/negin-kitchen.jpg"
              style={{
                borderRadius: `var(--chakra-radii-lg)`,
                boxShadow: `var(--chakra-shadows-lg)`,
              }}
              width={500}
            />
            <StaticImage
              alt="This home overlooking Bear Lake showcases timeless craftsman style."
              height={500}
              src="../images/harris-exterior.jpg"
              style={{
                borderRadius: `var(--chakra-radii-lg)`,
                boxShadow: `var(--chakra-shadows-lg)`,
              }}
              width={500}
            />
          </Stack>
        </UniformBox>
      </Box>

      <Box
        bgGradient="linear(177deg, gray.100 80%, white 80%)"
        px={[4, , 8]}
        py={24}
      >
        <UniformBox>
          <Heading
            as="h2"
            color="gray.700"
            fontWeight="bold"
            textAlign="center"
          >
            The wait? Well worth it.
          </Heading>
          <Text
            color="gray.700"
            mt={6}
            fontSize="xl"
            fontWeight="semibold"
            textAlign="center"
          >
            There's no other feeling quite like moving into a brand new,
            custom-built home.
          </Text>
          <Flex
            align="flex-end"
            bg="white"
            justify="space-evenly"
            letterSpacing="tight"
            maxW="550px"
            mx="auto"
            mb={2}
            mt={16}
            py={8}
            rounded="lg"
            shadow="md"
          >
            <Box fontSize={{ base: `sm`, md: `normal` }} p={[1, 2, 4]}>
              <List spacing={4}>
                {Object.keys(customBuildComparedValues).map(
                  (key: string, index: number) => (
                    <ListItem fontWeight="semibold" key={index}>
                      {key}:
                    </ListItem>
                  )
                )}
              </List>
            </Box>
            <Stack
              bg="blue.50"
              fontSize={{ base: `sm`, md: `normal` }}
              p={[1, 2, 4]}
              spacing="32px"
            >
              <Stack align="center" space={6}>
                <Icon
                  aria-hidden
                  as={FaHandshake}
                  color="blue.700"
                  fontSize="4xl"
                />
                <Text fontSize="xl" fontWeight="extrabold">
                  Buying
                </Text>
              </Stack>
              <List fontSize={{ base: `sm`, md: `normal` }} spacing={4}>
                {Object.keys(purchasedHomeComparedValues).map(
                  (key: string, index: number) => {
                    const {
                      isSuperior,
                      value,
                    }: ComparedValue = purchasedHomeComparedValues[key]
                    return (
                      <ListItem key={index}>
                        <ListIcon
                          as={isSuperior ? FaCheckCircle : FaTimesCircle}
                          color={isSuperior ? `green.400` : `gray.400`}
                          fontSize={{ base: `sm`, md: `xl` }}
                          marginEnd={[1, 2]}
                        />
                        {value}
                      </ListItem>
                    )
                  }
                )}
              </List>
            </Stack>
            <Stack
              bg="green.50"
              fontSize={{ base: `sm`, md: `normal` }}
              p={[1, 2, 4]}
              spacing="32px"
            >
              <Stack align="center" space={6}>
                <Icon
                  aria-hidden
                  as={FaHammer}
                  color="green.700"
                  fontSize="4xl"
                />
                <Text fontSize="xl" fontWeight="extrabold">
                  Building
                </Text>
              </Stack>
              <List spacing={4}>
                {Object.keys(customBuildComparedValues).map(
                  (key: string, index: number) => {
                    const {
                      isSuperior,
                      value,
                    }: ComparedValue = customBuildComparedValues[key]
                    return (
                      <ListItem key={index}>
                        <ListIcon
                          as={isSuperior ? FaCheckCircle : FaTimesCircle}
                          color={isSuperior ? `green.400` : `gray.400`}
                          fontSize={{ base: `sm`, md: `xl` }}
                          marginEnd={[1, 2]}
                        />
                        {value}
                      </ListItem>
                    )
                  }
                )}
              </List>
            </Stack>
          </Flex>
        </UniformBox>
      </Box>

      <Box py={24}>
        <UniformBox>
          <Heading
            as="h4"
            color="gray.700"
            fontSize="4xl"
            fontWeight="extrabold"
            letterSpacing="tight"
            textAlign="center"
          >
            Partner with a builder you can{" "}
            <chakra.span color="blue.600">trust</chakra.span>.
          </Heading>
          <Stack
            align="center"
            direction={{ base: `column`, md: `row` }}
            justify="space-between"
            mt={12}
            spacing="24px"
          >
            <Box w={{ base: `100%`, md: `50%`, lg: `40%` }}>
              <Img
                alt="The Gamble home in Lake Glenville, NC"
                as={GatsbyImage}
                h={{ base: `350px`, sm: `400px`, lg: `350px` }}
                image={testimonialImg.gatsbyImageData}
                rounded="lg"
                shadow="lg"
              />
            </Box>
            <Stack
              pl={{ base: 0, md: 2, lg: 4 }}
              w={{ base: `100%`, md: `50%`, lg: `60%` }}
            >
              <Text color="gray.700" fontSize="3xl" fontWeight="bold">
                “I needed to [work with] a company that I could totally trust in
                two specific areas … financial trustworthiness, and quality (not
                shortcuts). We achieved both.”
              </Text>
              <Text
                fontSize="lg"
                fontWeight="bold"
                lineHeight="shorter"
                mb={-2}
                mt={4}
              >
                Gary Gamble
              </Text>
              <Text fontSize="md" fontStyle="italic" mb={6}>
                Lake Glenville, NC
              </Text>
              <Button
                as={GatsbyLink}
                colorScheme="red"
                to="/client-stories/gamble/"
                variant="solid"
                w="220px"
              >
                Read Gary's Story
              </Button>
            </Stack>
          </Stack>
        </UniformBox>
      </Box>

      <Box bgGradient="linear(182deg, white 15%, green.600 15%, green.600 72%, gray.50 72%)">
        <UniformBox>
          <Stack direction={[`column`]} py={6} spacing="0px">
            <Box h={[500, 600, 500]} mb="12px" px={[2, 4, 6, 8]}>
              <StaticImage
                alt="This custom firepit represented the finishing touch of this client's home."
                src="../images/negin-firepit.jpg"
                style={{
                  borderRadius: `var(--chakra-radii-lg)`,
                  boxShadow: `var(--chakra-shadows-lg)`,
                  height: `100%`,
                }}
                width={1000}
              />
            </Box>
            <Stack
              align={[`start`, , `end`]}
              direction="row"
              justify="center"
              spacing="10px"
            >
              <Box
                dangerouslySetInnerHTML={{
                  __html: `
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 125" style="enable-background:new 0 0 100 100;" xml:space="preserve" data-attribution="Created by kiddo from the Noun Project"><path fill="#E53E3E" d="M20.1,80.9c-0.1,0.6-0.2,2,0.5,2c8.3,0.3,16.6,1,24.8,2.1c0.8,0.1,1.3-1,1.5-1.7c0.1-0.5,0.4-2.1-0.5-2.2  c-7.3-1-14.6-1.6-21.9-1.9c11.4-3.6,23-7.3,33-14c4.6-3.1,8.9-6.8,12.4-11.1c3.9-4.8,6.6-10.3,8.2-16.2c1.9-7.1,2.3-14.5,1.7-21.7  c0-0.6-0.4-1.5-1.1-1c-0.7,0.5-1.1,1.7-1,2.6c0.4,5.9,0.3,12-1,17.8c-1.2,5.4-3.4,10.3-6.8,14.7C63.1,59,53.4,64.8,43.4,68.9  c-5.4,2.2-10.9,4-16.5,5.8c2.7-4,4.7-8.5,5.9-13.2c0.1-0.6,0.3-1.9-0.5-2.2c-0.8-0.2-1.3,1.2-1.5,1.7c-1.6,6.5-5.1,12.4-10.1,17.1  c-0.6,0.6-0.9,1.9-0.7,2.7C20,80.8,20,80.9,20.1,80.9z"/></svg>
            `,
                }}
                mt={[-2, , -6]}
                transform="rotate(110deg)"
                w={[`15vw`, `75px`]}
              />
              <Text
                color="gray.700"
                fontSize={[`lg`, , `xl`]}
                fontWeight="semibold"
                textAlign="left"
                w={[`70vw`, `300px`, `500px`]}
              >
                Commonly overlooked: building a custom home is also an
                opportunity to design your dream backyard!
              </Text>
            </Stack>
          </Stack>
        </UniformBox>
      </Box>

      <Box bg="gray.50" py={24}>
        <UniformBox
          bg="white"
          px={[8, , 16]}
          py={12}
          rounded="xl"
          w="1200px"
          maxW="95vw"
          shadow="xl"
        >
          <Stack align="center" ref={contactSectionRef} spacing="24px">
            <Text
              color="gray.600"
              fontFamily="heading"
              fontSize="4xl"
              fontWeight="extrabold"
              letterSpacing="tight"
              lineHeight="shorter"
              textAlign="center"
            >
              With the right team, building a home can be{" "}
              <chakra.span color="red.500">easier</chakra.span>, more{" "}
              <chakra.span color="red.500">accessible</chakra.span>, and more{" "}
              <chakra.span color="red.500">rewarding</chakra.span> than you
              think.
            </Text>
            <Text color="gray.700" fontSize="xl" fontWeight="medium" mb={6}>
              We don't believe in hard sells or sales pitches — we believe in
              conversations. Our free, no-obligation consultations are an
              opportunity for us to learn about you and your project, for you to
              learn about us and how we operate, and for both of us to decide if
              we're the right fit for each other. Schedule yours today.
            </Text>
            <Stack
              align="start"
              direction={{ base: `column`, lg: `row` }}
              justify="space-between"
              ml={{ base: 0, md: `2.5%`, lg: `5%` }}
              spacing={[16, , , `auto`]}
              w={{ base: `100%`, md: `95%`, lg: `90%` }}
            >
              <Box
                flex="2"
                flexShrink={{ base: 1, lg: 2 }}
                h="auto"
                maxW="95vw"
                pb={{ base: 12, lg: 0 }}
              >
                <Box mx="auto" w="325px">
                  <ContactForm
                    context={[]}
                    eventLabel="Schedule Consult (Get Started)"
                  />
                </Box>
              </Box>
              <Box
                bg="gray.50"
                flex="1"
                px={[1, 4, , 14]}
                py={[10]}
                rounded="lg"
                textAlign="center"
              >
                <Avatar
                  bg="gray.50"
                  boxSize="76px"
                  mt={[`-72px`]}
                  name="Chuck Nuggets"
                  p={2}
                  src="/jim-lisa.jpeg"
                />
                <Text mb={4}>
                  “We loved working with Sundog Homes to build our mountain
                  retreat. They made things easy and stress free by being
                  customer focused and by providing a way to keep track of
                  progress online for remote owners like us.”
                </Text>
                <Text
                  as="cite"
                  fontSize="lg"
                  fontStyle="normal"
                  fontWeight="bold"
                >
                  Jim Bacon
                </Text>
                <Text fontSize="md">Custom Project, Cullowhee</Text>
              </Box>
            </Stack>
          </Stack>
        </UniformBox>
      </Box>
      <Box
        bgGradient="linear(179deg, gray.50 5%, gray.600 5%)"
        w="100vw"
        pb={24}
      >
        <UniformBox>
          <Stack pt={24} spacing="16px">
            <Text
              color="gray.50"
              fontFamily="heading"
              fontSize="5xl"
              fontWeight="extrabold"
              lineHeight="shorter"
            >
              Answers are just a phone call away.
            </Text>
            <Text color="gray.200" fontSize="xl" pb={4}>
              After several decades of building custom homes, we've pretty much
              seen it all, we've learned from everything we've seen, and we're
              happy to share what we've learned — whether you choose to build
              with us or not. Have a question? Give us a call.
            </Text>
            <Box>
              <Button
                as={ChakraLink}
                color="red.300"
                fontSize="3xl"
                fontWeight="bold"
                href="tel:8287745720"
                leftIcon={<Icon as={FaPhone} mr={4} />}
                variant="link"
              >
                (828) 774-5720
              </Button>
            </Box>
          </Stack>
        </UniformBox>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query {
    img: contentfulAsset(contentful_id: { eq: "469GfIQ5nEk8ZllEDa54BB" }) {
      description
      gatsbyImageData(width: 750)
    }
    testimonialImg: contentfulAsset(
      contentful_id: { eq: "79RSwagE3T3A0kru9hmqhE" }
    ) {
      description
      gatsbyImageData(width: 500)
    }
  }
`

export default GetStarted
